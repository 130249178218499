.Nav__customIcon {
  position: relative;
  bottom: 4px;
  width: 16px;
  height: 16px;
}

.ant-menu-item-selected {
  background: transparent !important;
}

.ant-layout-header {
    overflow: hidden;
}

.menuWrapper {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.ant-menu-light, .ant-menu-dark {
  background-color: transparent;
}