.wrapper-video {
    position: relative;
    background-color: #000;
    border: 3px solid rgb(152, 202, 255);
    border-radius: 15px;
    overflow: hidden;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}