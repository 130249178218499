/* @import '~antd/dist/antd.css'; */

.ChartPage__header {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
	padding-right: 20px;
  }

.ChartPage__fields {
	display: flex;
	justify-content: center;
}


  