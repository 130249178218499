.ant-card {
  background-color: transparent !important;
}

.ant-card-head {
  background-color: blue;
}

.ant-layout {
  border-radius: 0.35rem 0.35rem 0 0;
}

.ant-card-head-wrapper  {
  position: relative;
}

.ant-card-body {
  padding: 0px;
}

.ant-card-small >.ant-card-body {
  padding: 0px;
  border-radius:20px;
  background-color: transparent !important;
}

.userName {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  left: 20px;
  top:12px;
  margin-left: 0px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
}

.translationName {
  padding: 2px 8px;
  border-radius: 5px;
  background-color: #80808061;
  border-radius: 5px; 
}