.DefaultHeader__wrapper {
  display: flex;
  flex-direction: column;
}

.NotificationForAll {
  background-color: #d44040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.NotificationForAll__text_link,
.NotificationForAll__text_link:active,
.NotificationForAll__text_link:hover {
  text-decoration: none;
  color: white;
}

.NotificationForAll__text {
  color: white;
}

.logo {
  width: 120px;
  height: 31px;
  float: left;
}

.ant-layout {
  min-height: 99vh;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

.Header {
  display: flex;
  background-color: #001529;
  justify-content: space-between;
}

.ant-layout-header {
  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.Header__profileBlockWrapper {
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}

.Header__profileBlock {
  margin-left: 15px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.Header__profileBlock:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}

.profileBlock__img {
  width: 24px;
  height: 24px;
  position: relative;
}

.adminImg {
  width: 27px;
  height: 27px;
}
