.NavContainer {
  position: relative;
  padding: 23px 0;
  color: #A3ABB2 !important;
}

.NavContainer:hover .NavHover {
  color: #fff !important;
}
    
.NavHover {
  color: #A3ABB2 !important;
}
   
.NavContainer:hover .NavHover {
  color: #fff !important;
}

.menuWrapper {
  display: flex;
  position: absolute;
  justify-content: start;
  width: 100%;
  padding-left: 5%;
}

.menuNav {
  width: 80%; 
}

.activeNavItem {
  background-color: #1677ff;
  padding: 0px 16px;
  margin: 0px -16px;
}